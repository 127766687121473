<template>
  <div
    class="row"
    v-for="(row, rowIndex) in getComputedDesignStructure"
    :key="rowIndex"
  >
    <div
      class="block"
      v-for="(block, blockIndex) in row.childs"
      :key="blockIndex"
      :class="{ hasTitle: block.title || designCenterIsVisible }"
    >
      <BlockHeader
        v-if="block.title || designCenterIsVisible"
        :rowIndex="rowIndex"
        :blockIndex="blockIndex"
        :title="block.title"
      />
      <div class="blockContent">
        <div
          class="fieldRow"
          v-for="(fieldRow, fieldRowIndex) in block.childs"
          :key="fieldRowIndex"
        >
          <div
            class="fieldContainer"
            :class="{ showBorders: designCenterIsVisible }"
            v-for="(fieldContainer, fieldContainerIndex) in fieldRow.childs"
            :key="fieldContainerIndex"
            @drop="
              onDrop(
                $event,
                rowIndex,
                blockIndex,
                fieldRowIndex,
                fieldContainerIndex
              )
            "
            @dragenter.prevent
            @dragover.prevent
          >
            <!-- INPUT -->
            <div v-if="fieldContainer.child?.type == 'STRING'">
              <Input
                :label="fieldContainer.child.label"
                :id="fieldContainer.child.id"
                v-model="
                  getApiDataByChild(fieldContainer.child)[
                    fieldContainer.child.dataIndex.fieldName
                  ]
                "
              />
            </div>

            <!-- INPUT NUMBER -->

            <div v-if="fieldContainer.child?.type == 'INTEGER'">
              <Input
                :label="fieldContainer.child.label"
                :id="fieldContainer.child.id"
                v-model="
                  getApiDataByChild(fieldContainer.child)[
                    fieldContainer.child.dataIndex.fieldName
                  ]
                "
                :type="'number'"
              />
            </div>

            <!-- LIST -->

            <div v-if="fieldContainer.child?.type == 'LIST'">
              <CustomSelect
                :label="fieldContainer.child.label"
                :id="fieldContainer.child.id"
                :apiIndex="fieldContainer.child.apiIndex"
                :apiUrl="fieldContainer.child.apiUrl"
                v-model="
                  getApiDataByChild(fieldContainer.child)[
                    fieldContainer.child.dataIndex.fieldName
                  ]
                "
                :referenceKey="fieldContainer.child.referenceKey"
                :referenceIndex="fieldContainer.child.referenceIndex"
                :referenceUrl="fieldContainer.child.referenceUrl"
                :referenceDataIndex="fieldContainer.child.referenceDataIndex"
              />
            </div>

            <!-- TABLE -->

            <div v-else-if="fieldContainer.child?.type == 'TABLE'">
              <ListingTable
                :label="fieldContainer.child.label"
                :id="fieldContainer.child.id"
                :apiIndex="fieldContainer.child.apiIndex"
                :apiUrl="fieldContainer.child.apiUrl"
                :apiDeleteUrl="fieldContainer.child.apiDeleteUrl"
                :clientEditUrl="fieldContainer.child.clientEditUrl"
                :clientCreateUrl="fieldContainer.child.clientCreateUrl"
                :hasEditButton="!!fieldContainer.child.clientEditUrl"
                :hasDeleteButton="!!fieldContainer.child.apiDeleteUrl"
                :fields="filteredFieldNames(fieldContainer.child.fields)"
              />
            </div>

            <!-- SUBTABLE -->

            <div v-else-if="fieldContainer.child?.type == 'SUBTABLE'">
              <SubtableTable
                :id="fieldContainer.child.id"
                :label="fieldContainer.child.label"
                :apiIndex="fieldContainer.child.apiIndex"
                :apiUrl="fieldContainer.child.apiUrl"
                :apiDeleteUrl="fieldContainer.child.apiDeleteUrl"
                :clientEditUrl="fieldContainer.child.clientEditUrl"
                :clientCreateUrl="fieldContainer.child.clientCreateUrl"
                :hasEditButton="!!fieldContainer.child.clientEditUrl"
                :hasDeleteButton="!!fieldContainer.child.hasDeleteButton"
                :fields="filteredFieldNames(fieldContainer.child.fields)"
              />
            </div>

            <!-- SUBTABLE ADD -->

            <div v-else-if="fieldContainer.child?.type == 'SUBTABLE-ADD'">
              <SubtableAddTable
                :id="fieldContainer.child.id"
                :label="fieldContainer.child.label"
                :apiIndex="fieldContainer.child.apiIndex"
                :apiUrl="fieldContainer.child.apiUrl"
                :referenceIndex="fieldContainer.child.referenceIndex"
                :fields="filteredFieldNames(fieldContainer.child.fields)"
              />
            </div>

            <!-- PASSSWORD -->

            <div v-else-if="fieldContainer.child?.type == 'PASSWORD'">
              <Input
                :label="fieldContainer.child.label"
                :id="fieldContainer.child.id"
                v-model="
                  getApiDataByChild(fieldContainer.child)[
                    fieldContainer.child.dataIndex.fieldName
                  ]
                "
                :type="'password'"
              />
            </div>

            <!-- PASSWORD-VALIDATION -->

            <div
              v-else-if="fieldContainer.child?.type == 'PASSWORD-VALIDATION'"
            >
              <Input
                :label="fieldContainer.child.label"
                :id="fieldContainer.child.id"
                v-model="
                  getApiDataByChild(fieldContainer.child)[
                    fieldContainer.child.dataIndex.fieldName
                  ]
                "
                :type="'password'"
              />
            </div>

            <!-- BOOLEAN / SWITCH -->

            <div v-else-if="fieldContainer.child?.type == 'BOOLEAN'">
              <div class="boolean-container">
                <label class="label">{{ fieldContainer.child.label }}</label>
                <Switch
                  :id="fieldContainer.child.id"
                  v-model="
                    getApiDataByChild(fieldContainer.child)[
                      fieldContainer.child.dataIndex.fieldName
                    ]
                  "
                />
              </div>
            </div>

            <!-- END -->

            <FieldOverlay
              class="overlay"
              :rowIndex="rowIndex"
              :blockIndex="blockIndex"
              :fieldRowIndex="fieldRowIndex"
              :fieldContainerIndex="fieldContainerIndex"
              draggable="true"
              @dragstart="
                startDrag(
                  $event,
                  fieldContainer.child.id,
                  rowIndex,
                  blockIndex,
                  fieldRowIndex,
                  fieldContainerIndex
                )
              "
              @mousedown="test"
            />
          </div>
        </div>

        <BlockOverlay
          class="block-overlay"
          :rowIndex="rowIndex"
          :blockIndex="blockIndex"
        />
      </div>
    </div>
  </div>

  <div class="button-container">
    <Button
      :label="'Ajouter un premier bloc'"
      :symbol="'add'"
      class="rowButton"
      @click="addRow(getDesignStructure.length)"
      v-if="designCenterIsVisible && hasNoRows"
    />
  </div>
</template>

<script>
import Button from "@/components/basics/Button";
import Input from "@/components/basics/Input";
import CustomSelect from "@/components/design/CustomSelect";
import SubtableAddTable from "@/components/design/SubtableAddTable";
import SubtableTable from "@/components/design/SubtableTable";
import ListingTable from "@/components/design/ListingTable";
import FieldOverlay from "@/components/design/FieldOverlay";
import BlockOverlay from "@/components/design/BlockOverlay";
import BlockHeader from "@/components/design/BlockHeader";
import Switch from "@/components/basics/Switch";

import dataIndexMixin from "@/mixins/dataIndex.mixin";

export default {
  name: "design-page",
  mixins: [dataIndexMixin],
  components: {
    Button,
    Input,
    CustomSelect,
    SubtableAddTable,
    SubtableTable,
    ListingTable,
    FieldOverlay,
    BlockOverlay,
    BlockHeader,
    Switch,
  },
  computed: {
    getDesignStructure() {
      return this.$store.state.design.designStructure;
    },
    // Replace Ids by field
    getComputedDesignStructure() {
      let computedDesignStructure = JSON.parse(
        JSON.stringify(this.getDesignStructure)
      );

      for (const rows of computedDesignStructure) {
        for (const blocks of rows.childs) {
          for (const fieldRows of blocks.childs) {
            for (const fieldContainer of fieldRows.childs) {
              if (fieldContainer.child) {
                fieldContainer.child = this.getFieldDataById(
                  fieldContainer.child
                );
              }
            }
          }
        }
      }

      return computedDesignStructure;
    },
    designCenterIsVisible() {
      return this.$store.state.design.designCenterIsVisible;
    },
    designFields() {
      return this.$store.state.design.designFields;
    },
    tableName() {
      return this.$route.meta.tableName;
    },
    fieldId() {
      return this.$route.params.id;
    },
    currentRouteName() {
      return this.$route.name;
    },
    hasNoRows() {
      if (this.getDesignStructure && this.getDesignStructure.length > 0) {
        return false;
      }
      return true;
    },
  },
  methods: {
    getApiData(apiIndex) {
      return this.$store.getters["api/getApiDataByApiIndex"](apiIndex);
    },
    getApiDataByDataIndex(dataIndex) {
      return this.$store.getters["api/getApiDataByDataIndex"](dataIndex);
    },
    getApiDataByChild(child) {
      let apiData = this.getApiData(child.apiIndex);

      if (child.dataIndex.linkIndexes) {
        for (let i in child.indexData.linkIndexes) {
          apiData = apiData[child.indexData.linkIndexes[i]];
        }
      }

      return apiData;
    },
    cleanApiData(tableName) {
      let data = [];

      if (Array.isArray(this.getApiData(tableName))) {
        data = JSON.parse(JSON.stringify(this.getApiData(tableName)));

        for (var i in data) {
          for (var j in data[i])
            if (Array.isArray(data[i][j])) {
              delete data[i][j];
            }
        }
      }

      return data;
    },
    addRow(rowIndex) {
      this.$store.dispatch("design/addRow", { rowIndex: rowIndex });
    },
    onDrop(evt, rowIndex, blockIndex, fieldRowIndex, fieldContainerIndex) {
      if (this.designCenterIsVisible) {
        // remove old data if dragged from screen

        if (evt.dataTransfer.getData("oldRowIndex")) {
          this.$store.dispatch("design/removeField", {
            rowIndex: evt.dataTransfer.getData("oldRowIndex"),
            blockIndex: evt.dataTransfer.getData("oldBlockIndex"),
            fieldRowIndex: evt.dataTransfer.getData("oldFieldRowIndex"),
            fieldContainerIndex: evt.dataTransfer.getData(
              "oldFieldContainerIndex"
            ),
          });
        }

        // add to new field

        const fieldId = evt.dataTransfer.getData("fieldId");

        this.$store.dispatch("design/addFieldById", {
          rowIndex: rowIndex,
          blockIndex: blockIndex,
          fieldRowIndex: fieldRowIndex,
          fieldContainerIndex: fieldContainerIndex,
          fieldId: fieldId,
        });
      }
    },
    getFieldDataById(fieldId) {
      return this.designFields.find((el) => el.id == fieldId);
    },
    filteredFieldNames(data) {
      var fieldNames = [];

      for (var index in data) {
        fieldNames.push({
          label: data[index].label,
          name: data[index].dataIndex.fieldName,
          queryParameter: data[index].dataIndex.linkIndexes
            ? data[index].dataIndex.linkIndexes.join("/") +
              "." +
              data[index].dataIndex.fieldName
            : data[index].dataIndex.fieldName,
          dataIndex: data[index].dataIndex,
        });
      }

      return fieldNames;
    },
    computedFieldRowChilds(fieldRowChilds) {
      return fieldRowChilds;
    },
    startDrag(
      evt,
      id,
      rowIndex,
      blockIndex,
      fieldRowIndex,
      fieldContainerIndex
    ) {
      if (id) {
        evt.dataTransfer.dropEffect = "move";
        evt.dataTransfer.effectAllowed = "move";
        evt.dataTransfer.setData("fieldId", id);

        evt.dataTransfer.setData("oldRowIndex", rowIndex);
        evt.dataTransfer.setData("oldBlockIndex", blockIndex);
        evt.dataTransfer.setData("oldFieldRowIndex", fieldRowIndex);
        evt.dataTransfer.setData("oldFieldContainerIndex", fieldContainerIndex);
      }
    },
  },
  async updated() {
    await this.$store.dispatch("design/refreshActiveFields");
  },
};
</script>

<style lang="less" scoped>
.row {
  .flex-row-v-top();

  &:not(:first-child) {
    margin-top: 32px;
  }

  .block {
    width: 100%;

    &:not(:first-child) {
      margin-left: 32px;
    }

    .blockContent {
      width: 100%;
      min-height: 30px;
      padding: 24px;
      background: @white;
      border-radius: 12px;
      position: relative;
      .flex-center();
      .flex-col();

      &:hover {
        .rowBlockButton {
          display: initial;
        }

        .block-overlay {
          opacity: 1;
        }
      }
    }

    &.hasTitle {
      .blockContent {
        border-radius: 0 0 12px 12px;
      }
    }

    .fieldRow {
      width: 100%;
      position: relative;
      .flex-row();

      &:not(&:first-child) {
        margin-top: 32px;
      }

      &:hover {
        .fieldContainerButton {
          display: initial;
        }

        .fieldRowButton {
          display: initial;
        }
      }

      .fieldContainer {
        min-height: 58px;
        width: 100%;
        position: relative;

        &.showBorders {
          border: 1px dashed @grey;
        }

        &:not(:first-child) {
          margin-left: 10px;
        }

        &:hover {
          .fieldContainerButton {
            display: initial;
          }

          .overlay {
            opacity: 1;
          }
        }

        .fieldContainerButton {
          position: absolute;
          right: 5px;
          top: 50%;
          z-index: 2;

          transform: translateY(-50%);
          display: none;
        }
      }

      .fieldRowButton {
        position: absolute;
        left: 50%;
        bottom: 0;
        z-index: 2;

        transform: translateX(-50%) translateY(50%);
        display: none;
      }
    }

    .rowBlockButton {
      position: absolute;
      right: 0;
      top: 50%;

      transform: translateX(50%) translateY(-50%);
      display: none;
    }
  }
}

.button-container {
  .flex-col-h-left();

  .rowButton {
    margin-left: 0px;
    flex-basis: auto;
  }
}

.boolean-container {
  height: 56px;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  .flex-row-v-center();

  .label {
    margin-right: 12px;
    .label-large-font();
  }
}
</style>
