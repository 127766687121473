<template>
  <TableHeaderTopbar :label="tableTitle">
    <router-link class="link" :to="{ name: clientCreateUrl }">
      <Button :label="'Ajouter'" :symbol="'add'" :type="'action'" class="add-button" />
    </router-link>
  </TableHeaderTopbar>

  <!-- TABLE -->
  <div class="scrollableContainer">
    <Table :fieldNames="fields" :tableData="tableData" :hasEditButton="hasEditButton" :hasDeleteButton="hasDeleteButton"
      @deleteClick="deleteClick" @editClick="editClick" @dblClick="editClick"
      @tableColumnHeaderClick="tableColumnHeaderClick" class="theme border" />
  </div>
</template>

<script>
import Table from "@/components/basics/Table.vue";
import Button from "@/components/basics/Button.vue";
import TableHeaderTopbar from "@/components/TableHeaderTopbar.vue";

export default {
  name: "subtable-table",
  components: { Table, Button, TableHeaderTopbar },
  props: {
    label: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
    apiIndex: {
      type: String,
      required: true,
    },
    apiUrl: {
      type: String,
      required: true,
    },
    apiDeleteUrl: {
      type: String,
      default: null,
    },
    clientEditUrl: {
      type: String,
      default: null,
    },
    clientCreateUrl: {
      type: String,
      default: null,
    },
    hasEditButton: {
      type: Boolean,
      default: false,
    },
    hasDeleteButton: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Array,
      required: true,
    },
  },
  computed: {
    createQueryParameter() {
      let names = [];

      for (var i in this.fields) {
        names.push(this.fields[i].queryParameter);
      }

      return names.join(",");
    },
    tableData() {
      return this.getApiData(this.apiIndex);
    },
    pageTitle() {
      return this.$route.meta.title;
    },
    tableTitle() {
      return this.label || this.pageTitle;
    },
  },
  methods: {
    getApiData(apiIndex) {
      return this.$store.getters["api/getApiDataByApiIndex"](apiIndex);
    },
    async deleteClick(id) {
      await this.$store.dispatch("api/delete", {
        url: this.replaceUrlId(this.apiDeleteUrl, id),
      });

      return this.refreshData();
    },
    editClick(id) {
      return this.$router.push(`/${this.replaceUrlId(this.clientEditUrl, id)}`);
    },
    replaceUrlId(url, id) {
      return url.replace(":id", id);
    },
    async refreshData() {
      await this.$store.dispatch("api/get", {
        url: this.apiUrl,
        apiIndex: this.apiIndex,
        params: { fields: this.createQueryParameter },
      });
    },
    async tableColumnHeaderClick(orderData) {
      await this.$store.dispatch("api/get", {
        url: this.apiUrl,
        apiIndex: this.apiIndex,
        params: {
          fields: this.createQueryParameter,
          orderBy: orderData.orderBy,
          order: orderData.order
        },
      });
    },
  },
  async created() {
    await this.refreshData();
  },
};
</script>
