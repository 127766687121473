<template>
  <div class="scrollableContainer">
    <Table :fieldNames="fields" :tableData="getApiData(apiIndex)" :hasCheckbox="true"
      :checkedValues="getCheckboxData(apiIndex)" @checked="checked" @unchecked="unchecked"
      @tableColumnHeaderClick="tableColumnHeaderClick" />
  </div>
</template>

<script>
import Table from "@/components/basics/Table.vue";

export default {
  name: "subtable-add-table",
  components: { Table },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    apiIndex: {
      type: String,
      required: true,
    },
    apiUrl: {
      type: String,
      required: true,
    },
    referenceIndex: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
  },
  computed: {
    createQueryParameter() {
      let names = [];

      for (var i in this.fields) {
        names.push(this.fields[i].queryParameter);
      }

      return names.join(",");
    },
    currentUrl() {
      return this.$route.path;
    },
    apiData() {
      return this.$store.state.api.data;
    },
    fieldId() {
      return this.$route.params.id;
    },
  },
  methods: {
    getApiData(apiIndex) {
      return this.$store.getters["api/getApiDataByApiIndex"](apiIndex);
    },
    getOriginalCheckboxData(apiIndex) {
      return this.$store.getters["api/getOriginalCheckboxDataByApiIndex"](
        apiIndex
      );
    },
    getCheckboxData(apiIndex) {
      return this.$store.getters["api/getCheckboxDataByApiIndex"](apiIndex);
    },
    checked(id) {
      const data = this.getApiData(this.apiIndex);
      const checkboxData = this.getCheckboxData(this.apiIndex);

      const elem = data.find((el) => el.id == id);

      if (!checkboxData.find((el) => el.id == elem.id)) {
        this.$store.dispatch("api/addCheckboxData", {
          data: elem,
          apiIndex: this.apiIndex,
        });
      }
    },
    unchecked(id) {
      this.$store.dispatch("api/removeCheckboxDataById", {
        id: id,
        apiIndex: this.apiIndex,
      });
    },
    replaceUrlId(url, id) {
      return url.replace(":id", id);
    },
    async tableColumnHeaderClick(orderData) {
      await this.$store.dispatch("api/get", {
        url: this.replaceUrlId(this.apiUrl, this.fieldId),
        apiIndex: this.apiIndex,
        params: {
          fields: this.createQueryParameter,
          notRequired: true,
          orderBy: orderData.orderBy,
          order: orderData.order
        },
        setDefaultCheckboxData: true,
        referenceIndex: this.referenceIndex,
      });
    },
  },
  async created() {
    await this.$store.dispatch("api/get", {
      url: this.replaceUrlId(this.apiUrl, this.fieldId),
      apiIndex: this.apiIndex,
      params: { fields: this.createQueryParameter, notRequired: true },
      setDefaultCheckboxData: true,
      referenceIndex: this.referenceIndex,
    });
  },
};
</script>

<style lang="less" scoped></style>