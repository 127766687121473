<template>
  <div class="block-overlay" v-if="designCenterIsVisible">
    <IconButton
      :icon="'add'"
      :tooltip="'Ajouter un bloc au dessus'"
      :size="'16px'"
      :padding="'3px'"
      :tooltipPlacement="'top'"
      :color="'white'"
      :background="'darker-green'"
      class="top-button"
      @click="addRow(rowIndex)"
    />

    <IconButton
      :icon="'add'"
      :tooltip="'Ajouter un bloc à gauche'"
      :size="'16px'"
      :padding="'3px'"
      :tooltipPlacement="'right'"
      :color="'white'"
      :background="'darker-green'"
      class="left-button"
      @click="addBlock(rowIndex, blockIndex)"
    />

    <IconButton
      :icon="'add'"
      :tooltip="'Ajouter un bloc à droite'"
      :size="'16px'"
      :padding="'3px'"
      :tooltipPlacement="'left'"
      :color="'white'"
      :background="'darker-green'"
      class="right-button"
      @click="addBlock(rowIndex, blockIndex + 1)"
    />

    <IconButton
      :icon="'add'"
      :tooltip="'Ajouter un bloc en dessous'"
      :size="'16px'"
      :padding="'3px'"
      :tooltipPlacement="'top'"
      :color="'white'"
      :background="'darker-green'"
      class="bottom-button"
      @click="addRow(rowIndex + 1)"
    />
  </div>
</template>

<script>
import IconButton from "@/components/IconButton";

export default {
  name: "block-overlay",
  components: { IconButton },
  props: {
    rowIndex: {
      type: Number,
      required: true,
    },
    blockIndex: {
      type: Number,
      required: true,
    },
  },
  computed: {
    designCenterIsVisible() {
      return this.$store.state.design.designCenterIsVisible;
    },
  },
  methods: {
    addBlock(rowIndex, blockIndex) {
      this.$store.dispatch("design/addBlock", {
        rowIndex: rowIndex,
        blockIndex: blockIndex,
      });
    },
    addRow(rowIndex) {
        this.$store.dispatch("design/addRow", { rowIndex: rowIndex });
    },
  },
};
</script>

<style lang="less" scoped>
.block-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;

  .flex-center();

  .top-button {
    position: absolute;
    top: -11px;
  }

  .bottom-button {
    position: absolute;
    bottom: -11px;
  }
  .left-button {
    position: absolute;
    left: -11px;
  }
  .right-button {
    position: absolute;
    right: -11px;
  }
}
</style>