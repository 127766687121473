<template>
  <div>
    <!-- <Popper
      v-if="tooltip"
      :hover="true"
      :placement="tooltipPlacement"
      :content="tooltip"
      class="popper-content"
    > -->

    <!-- <div v-if="tooltip" class="popper-container">
      <div class="popper" :style="{ bottom: addSize(size) }">{{ tooltip }}</div> -->

    <GoogleIcon
      v-if="tooltip"
      :iconType="iconType"
      :icon="icon"
      :size="size"
      :padding="padding"
      :selected="selected"
      :color="color"
      :background="background"
      class="google-icon"
      @mouseover="mouseover"
      @mouseleave="mouseleave"
    />
    <!-- </div> -->

    <!-- </Popper> -->

    <GoogleIcon
      v-else
      :iconType="iconType"
      :icon="icon"
      :size="size"
      :padding="padding"
      :selected="selected"
      :color="color"
      :background="background"
    />
  </div>
</template>

<script>
import GoogleIcon from "./GoogleIcon";

export default {
  name: "icon-button",
  components: {
    GoogleIcon,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    iconType: {
      type: String,
    },
    tooltip: {
      type: String,
    },
    tooltipPlacement: {
      type: String,
      default: "top",
    },
    size: {
      type: String,
      default: "28px",
    },
    padding: {
      type: String,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
    },
    background: {
      type: String,
    },
  },
  methods: {
    addSize(size) {
      return `${parseInt(size) + 15}px`;
    },
    mouseover(e) {
      const elem = e.target.getBoundingClientRect();

      this.$store.dispatch("tooltip/setTooltip", {
        x: elem.left,
        y: elem.top,
        width: elem.width,
        height: elem.width,
        message: this.tooltip,
        placement: this.tooltipPlacement,
     });
    },
    mouseleave() {
      this.$store.dispatch("tooltip/hideTooltip");
    },
  },
};
</script>

<style lang="less">
// :root {
//   --popper-theme-background-color: rgba(51, 51, 51, 0.9);
//   --popper-theme-background-color-hover: rgba(51, 51, 51, 0.9);
//   --popper-theme-text-color: #ffffff;
//   --popper-theme-border-width: 0px;
//   --popper-theme-border-style: solid;
//   --popper-theme-border-radius: 6px;
//   --popper-theme-padding: 10px 20px;
//   --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
// }

// .popper-content {
//   font-size: 0.8rem;
// }

// .google-icon {
//   margin-top: 4px;
// }

.popper-container {
  position: relative;

  &:hover {
    .popper {
      opacity: 1;
    }
  }

  .popper {
    opacity: 0;
    user-select: none;
    pointer-events: none;
    position: fixed;
    background-color: rgba(51, 51, 51, 0.9);
    color: @white;
    padding: 10px 20px;
    z-index: 9999;
  }
}
</style>
