export default {
  methods: {
    parseDataIndex(dataIndex) {
      const fieldName = dataIndex.slice(-1)[0];
      const tableNames = dataIndex.slice(0, -1);

      return { tableNames: tableNames, fieldName: fieldName };
    },
  },
};
