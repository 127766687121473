<template>
  <div class="overlay" v-if="designCenterIsVisible">
    <IconButton
      :icon="'add'"
      :tooltip="'Ajouter une ligne au dessus'"
      :size="'16px'"
      :padding="'3px'"
      :tooltipPlacement="'top'"
      :color="'white'"
      :background="'blue'"
      class="top-button"
      @click="addFieldRow(rowIndex, blockIndex, fieldRowIndex)"
    />

    <IconButton
      :icon="'add'"
      :tooltip="'Ajouter un champ à gauche'"
      :size="'16px'"
      :padding="'3px'"
      :tooltipPlacement="'top'"
      :color="'white'"
      :background="'blue'"
      class="left-button"
      @click="
        addFieldContainer(
          rowIndex,
          blockIndex,
          fieldRowIndex,
          fieldContainerIndex
        )
      "
    />

    <IconButton
      :icon="'delete'"
      :tooltip="'Supprimer'"
      :size="'16px'"
      :padding="'3px'"
      :tooltipPlacement="'top'"
      :color="'white'"
      :background="'red'"
      @click="
        removeField(rowIndex, blockIndex, fieldRowIndex, fieldContainerIndex)
      "
    />

    <IconButton
      :icon="'add'"
      :tooltip="'Ajouter un champ à droite'"
      :size="'16px'"
      :padding="'3px'"
      :tooltipPlacement="'top'"
      :color="'white'"
      :background="'blue'"
      class="right-button"
      @click="
        addFieldContainer(
          rowIndex,
          blockIndex,
          fieldRowIndex,
          fieldContainerIndex + 1
        )
      "
    />

    <IconButton
      :icon="'add'"
      :tooltip="'Ajouter une ligne en dessous'"
      :size="'16px'"
      :padding="'3px'"
      :tooltipPlacement="'top'"
      :color="'white'"
      :background="'blue'"
      class="bottom-button"
      @click="addFieldRow(rowIndex, blockIndex, fieldRowIndex + 1)"
    />
  </div>
</template>

<script>
import IconButton from "@/components/IconButton";

export default {
  name: "field-overlay",
  components: { IconButton },
  props: {
    rowIndex: {
      type: Number,
      required: true,
    },
    blockIndex: {
      type: Number,
      required: true,
    },
    fieldRowIndex: {
      type: Number,
      required: true,
    },
    fieldContainerIndex: {
      type: Number,
      required: true,
    },
  },
  computed: {
    designCenterIsVisible() {
      return this.$store.state.design.designCenterIsVisible;
    },
  },
  methods: {
    addFieldContainer(
      rowIndex,
      blockIndex,
      fieldRowIndex,
      fieldContainerIndex
    ) {
      this.$store.dispatch("design/addFieldContainer", {
        rowIndex: rowIndex,
        blockIndex: blockIndex,
        fieldRowIndex: fieldRowIndex,
        fieldContainerIndex: fieldContainerIndex,
      });
    },
    addFieldRow(rowIndex, blockIndex, fieldRowIndex) {
      this.$store.dispatch("design/addFieldRow", {
        rowIndex: rowIndex,
        blockIndex: blockIndex,
        fieldRowIndex: fieldRowIndex,
      });
    },
    removeField(rowIndex, blockIndex, fieldRowIndex, fieldContainerIndex) {
      this.$store.dispatch("design/removeField", {
        rowIndex: rowIndex,
        blockIndex: blockIndex,
        fieldRowIndex: fieldRowIndex,
        fieldContainerIndex: fieldContainerIndex,
      });

      this.$store.dispatch("tooltip/hideTooltip");
    },
  },
};
</script>

<style lang="less" scoped>
.overlay {
  background-color: rgba(1, 1, 1, 0.3);
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0;
  border-radius: 3px;
  transition: opacity 150ms ease;
  top: 0;
  left: 0;

  .flex-center();

  .top-button {
    position: absolute;
    top: -11px;
  }

  .bottom-button {
    position: absolute;
    bottom: -11px;
  }
  .left-button {
    position: absolute;
    left: -11px;
  }
  .right-button {
    position: absolute;
    right: -11px;
  }
}
</style>