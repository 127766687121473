<template>
  <TableHeaderTopbar :label="label">
    <router-link v-if="this.fieldId" class="link" :to="this.replaceUrlId(`/${this.clientCreateUrl}`, this.fieldId)">
      <Button :label="'Ajouter'" :symbol="'add'" :type="'action'" class="add-button" />
    </router-link>
  </TableHeaderTopbar>

  <!-- TABLE -->
  <div class="scrollableContainer">
    <Table :fieldNames="fields" :tableData="tableData" :hasEditButton="hasEditButton" :hasDeleteButton="hasDeleteButton"
      @deleteClick="deleteClick" :cannotBeUsedInCreation="cannotBeUsedInCreation"
      @tableColumnHeaderClick="tableColumnHeaderClick" />
  </div>
</template>

<script>
import Table from "@/components/basics/Table.vue";
import Button from "@/components/basics/Button.vue";
import TableHeaderTopbar from "@/components/TableHeaderTopbar.vue";

export default {
  name: "subtable-table",
  components: { Table, Button, TableHeaderTopbar },
  props: {
    label: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
    apiIndex: {
      type: String,
      required: true,
    },
    apiUrl: {
      type: String,
      required: true,
    },
    apiDeleteUrl: {
      type: String,
      default: null,
    },
    clientEditUrl: {
      type: String,
      default: null,
    },
    clientCreateUrl: {
      type: String,
      default: null,
    },
    hasEditButton: {
      type: Boolean,
      default: false,
    },
    hasDeleteButton: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Array,
      required: true,
    },
  },
  computed: {
    createQueryParameter() {
      let names = [];

      for (var i in this.fields) {
        names.push(this.fields[i].queryParameter);
      }

      return names.join(",");
    },
    tableData() {
      return this.getApiData(this.apiIndex);
    },
    fieldId() {
      return this.$route.params.id;
    },
    cannotBeUsedInCreation() {
      if (!this.fieldId) return true;
      return this.fieldId.length == 0;
    },
  },
  methods: {
    async deleteClick(id) {
      const elem = this.getApiData(this.apiIndex).find((el) => el.id == id);

      if (elem) {
        const linkTableId = this.getLinktableId(elem);

        if (linkTableId !== -1) {
          await this.$store.dispatch("api/delete", {
            url: this.replaceUrlId(this.apiDeleteUrl, linkTableId),
          });

          this.refreshData();
        }
      }
    },
    getApiData(apiIndex) {
      return this.$store.getters["api/getApiDataByApiIndex"](apiIndex);
    },
    replaceUrlId(url, id) {
      return url.replace(":id", id);
    },
    async refreshData() {
      if (this.fieldId) {
        await this.$store.dispatch("api/get", {
          url: this.replaceUrlId(this.apiUrl, this.fieldId),
          apiIndex: this.apiIndex,
          params: { fields: this.createQueryParameter },
        });
      }
    },
    async tableColumnHeaderClick(orderData) {
      if (this.fieldId) {
        await this.$store.dispatch("api/get", {
          url: this.replaceUrlId(this.apiUrl, this.fieldId),
          apiIndex: this.apiIndex,
          params: {
            fields: this.createQueryParameter,
            orderBy: orderData.orderBy,
            order: orderData.order
          },
        });
      }
    },
    getLinktableId(elem) {
      for (let index in elem) {
        if (Array.isArray(elem[index])) {
          const includeTableData = elem[index][0];

          for (let subIndex in includeTableData) {
            if (
              typeof includeTableData[subIndex] === "object" &&
              includeTableData[subIndex] !== null
            ) {
              return includeTableData[subIndex].id;
            }
          }
        }
      }

      return -1;
    },
  },
  async created() {
    await this.refreshData();
  },
};
</script>

<style lang="less" scoped>
.add-button {
  margin: 6px;
}
</style>