<template>
  <span
    :class="[
      iconType,
      hover ? 'hover' : '',
      selected ? 'selected' : '',
      color ? color : '',
      background ? `background-${background}` : '',
    ]"
    class="google-icon"
    :style="{ fontSize: size, padding: padding, color }"
    >{{ icon }}</span
  >
</template>

<script>
export default {
  name: "google-icon",
  props: {
    icon: {
      type: String,
      required: true,
    },
    iconType: {
      type: String,
      default: "material-icons-outlined",
    },
    size: {
      type: String,
      default: "24px",
    },
    padding: {
      type: String,
      default: "3px",
    },
    hover: {
      type: Boolean,
      default: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: ''
    },
    background: {
      type: String,
      default: ''
    },
  },
};
</script>

<style lang="less" scoped>
.google-icon {
  color: @grey-font;
  background-color: @transparent;
  user-select: none;
  cursor: pointer;
  border-radius: 50%;

  &.hover:hover,
  &.selected {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &.white {
    color: @white;
  }

  &.background-blue {
    background-color: @blue;

    &.hover:hover,
    &.selected {
      background-color: @blue;
      opacity: 0.8;
    }
  }

  &.background-red {
    background-color: @error-red;

    &.hover:hover,
    &.selected {
      background-color: @error-red;
      opacity: 0.8;
    }
  }

  &.background-darker-green {
    background-color: @darker-green;

    &.hover:hover,
    &.selected {
      background-color: @darker-green;
      opacity: 0.8;
    }
  }

}
</style>
