<template>
  <table>
    <thead>
      <tr class="row">
        <th v-if="hasCheckbox" class="headercell"></th>
        <th class="headercell" v-for="(fieldName, fieldNameIndex) in fieldNamesCopy" :key="fieldNameIndex"
          @click="tableColumnHeaderClick(fieldName)">
          <div class="content">
            <span>{{ fieldName.label }}</span>
            <Icon v-if="fieldName.orderState" :name="iconNameByOrder(fieldName.orderState)" class="icon" />
            <span v-if="fieldName.orderPosition && orderCounter > 1" class="position">{{ fieldName.orderPosition
              }}</span>
          </div>
        </th>
        <th v-if="hasEditButton" class="headercell edit"></th>
        <th v-if="hasDeleteButton" class="headercell delete"></th>
      </tr>
    </thead>

    <tbody v-if="cannotBeUsedInCreation" class="body">
      <tr class="row">
        <td class="datacell" :colspan="fieldNamesCopy.length + 2">
          <span class="no-data">Indisponible en création</span>
        </td>
      </tr>
    </tbody>

    <tbody v-else-if="filteredTableData.length == 0" class="body">
      <tr class="row">
        <td class="datacell" :colspan="fieldNamesLength">
          <span class="no-data">Aucune donnée</span>
        </td>
      </tr>
    </tbody>

    <tbody v-else class="body">
      <tr v-for="(row, rowIndex) in filteredTableData" :key="rowIndex" class="row" @dblclick="emitDblClick(row.id)">
        <td v-if="hasCheckbox" class="datacell">
          <Checkbox :key="checkedValues.length" :checked="isChecked(row.id)"
            @check="(value) => emitCheckbox(value, row.id)" />
        </td>

        <td v-for="(value, key, index) in fieldNames" :key="index" class="datacell">
          <span>{{ getRowValue(row, value.dataIndex) }}</span>
        </td>

        <td v-if="hasEditButton" class="datacell edit">
          <GoogleSymbol :symbol="'edit'" :padding="'6px'" :size="'24px'" @click="emitEditClick(row.id)" :hover="true"
            :color="'black'" />
        </td>
        <td v-if="hasDeleteButton" class="datacell delete">
          <GoogleSymbol :symbol="'delete'" :padding="'6px'" :size="'24px'" @click="emitDeleteClick(row.id)"
            :hover="true" :color="'black'" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import Checkbox from "@/components/basics/Checkbox.vue";
import GoogleSymbol from "@/components/GoogleSymbol.vue";
import Icon from "@/components/Icon";

export default {
  name: "table-component",
  components: { Checkbox, GoogleSymbol, Icon },
  props: {
    fieldNames: {
      type: Array,
      required: true,
    },
    tableData: {
      type: Array,
      required: true,
    },
    hasEditButton: {
      type: Boolean,
      default: false,
    },
    hasDeleteButton: {
      type: Boolean,
      default: false,
    },
    hasCheckbox: {
      type: Boolean,
      default: false,
    },
    checkedValues: {
      type: Array,
      default: () => [],
    },
    cannotBeUsedInCreation: {
      type: Boolean,
      default: false,
    },
    activeFieldName: {
      type: String,
      default: null,
    },
  },
  data: () => {
    return {
      orderCounter: 0,
      fieldNamesCopy: [],
    }
  },
  watch: {
    fieldNames: {
      immediate: true,
      handler(newVal) { this.fieldNamesCopy = JSON.parse(JSON.stringify(newVal)); }
    }
  },
  computed: {
    fieldNamesLength() {
      let cpt = this.fieldNamesCopy.length;

      if (this.hasEditButton) cpt++;

      if (this.hasDeleteButton) cpt++;

      return cpt;
    },
    filteredTableData() {
      if (this.activeFieldName) {
        return this.tableData.filter(
          (el) => el[this.activeFieldName] && el[this.activeFieldName].length > 0
        );
      }

      return this.tableData;
    },
  },
  methods: {
    emitEditClick(id) {
      return this.$emit("editClick", id);
    },
    emitDeleteClick(id) {
      return this.$emit("deleteClick", id);
    },
    emitDblClick(id) {
      return this.$emit("dblClick", id);
    },
    isChecked(id) {
      return !!this.checkedValues.find((el) => el.id == id);
    },
    emitCheckbox(value, id) {
      if (value === true) return this.$emit("checked", id);
      else return this.$emit("unchecked", id);
    },
    getRowValue(row, dataIndex) {
      let result = row;

      if (dataIndex.linkIndexes) {
        for (let i in dataIndex.linkIndexes) {
          result = result[dataIndex.linkIndexes[i]];
        }
      }

      if (result === null) return "";

      if (result[dataIndex.fieldName] === true) return "Oui";
      if (result[dataIndex.fieldName] === false) return "Non";

      return result[dataIndex.fieldName];
    },
    tableColumnHeaderClick(fieldName) {
      var lastDeletedPosition = null;

      if (fieldName.orderState === undefined)
        fieldName.orderState = 0;

      fieldName.orderState = (fieldName.orderState + 1) % 3;

      if (fieldName.orderState === 1) {
        this.orderCounter += 1;
        fieldName.orderPosition = this.orderCounter;
      }
      else if (fieldName.orderState === 0) {
        this.orderCounter -= 1;
        lastDeletedPosition = fieldName.orderPosition;
        fieldName.orderPosition = undefined;

        this.refreshOrderPosition(lastDeletedPosition);
      }

      return this.$emit("tableColumnHeaderClick", this.generateOrderDataForQuery());
    },
    iconNameByOrder(orderState) {
      if (orderState === 1)
        return 'arrow_upward';
      else if (orderState === 2)
        return 'arrow_downward';
    },
    refreshOrderPosition(lastDeletedPosition) {
      for (const index in this.fieldNamesCopy) {
        if (this.fieldNamesCopy[index].orderPosition > lastDeletedPosition)
          this.fieldNamesCopy[index].orderPosition -= 1;
      }
    },
    generateOrderDataForQuery() {
      var orderTemporaryData = [];
      var orderData = { orderBy: [], order: [] };
      var orderDataIndex = null;
      var fieldName = null;

      // Sorting in orderTemporaryData

      for (var index in this.fieldNamesCopy) {
        fieldName = this.fieldNamesCopy[index];

        if (fieldName.orderPosition !== undefined) {
          orderDataIndex = fieldName.orderPosition - 1;
          orderTemporaryData[orderDataIndex] = [fieldName.queryParameter, this.orderStateToHuman(fieldName.orderState)];
        }
      }

      // Filling data for server api query

      for (index in orderTemporaryData) {
        orderData.orderBy.push(orderTemporaryData[index][0]);
        orderData.order.push(orderTemporaryData[index][1]);
      }

      return orderData;
    },
    orderStateToHuman(orderState) {
      if (orderState === 1)
        return 'ASC';
      else if (orderState === 2)
        return 'DESC';

      return null;
    }
  },
};
</script>

<style lang="less" scoped>
table {
  width: 100%;
  border-collapse: collapse;

  .body {
    .row {
      border-top: 1px solid @grey-table-border;

      &:hover {
        background-color: @darker;
      }
    }
  }

  .row {
    .headercell {
      padding: 16px;
      color: @grey-form-font;
      text-align: left;
      // background-color: #eff3f9;
      vertical-align: center;
      cursor: pointer;
      user-select: none;

      .label-large-font();

      &.edit,
      &.delete {
        width: 50px;
      }

      .content {
        .flex-row-v-center();

        .icon {
          margin-left: 12px;
          color: @white;
          background-color: @blue;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          flex-shrink: 0;
          .flex-center();
        }

        .position {
          margin-left: 6px;
          color: @blue;
        }
      }
    }

    .datacell {
      color: @grey-form-font;
      height: 52px;
      padding: 0 16px;
      // border: 1px solid @grey-table-border;
      .body-medium-font();

      &.edit,
      &.delete {
        padding: 0 16px 0 0;
      }

      .no-data {
        width: 100%;
        padding: 24px 0;
        .body-large-font();
        .flex-center();
      }
    }
  }
}
</style>
