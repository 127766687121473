<template>
  <div class="table-header-topbar">
    <span class="table-title">{{ this.label }}</span>
    <div class="spacer"></div>

    <slot />
  </div>
</template>

<script>
export default {
  name: "tableheader-topbar",
  props: {
    label: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="less" scoped>
.table-header-topbar {
  background-color: @theme-background-secondary-color;
  color: @theme-font-color;
  padding: 12px;
  border-radius: 12px 12px 0 0;
  .flex-row-v-center();

  .table-title {
    display: inline-block;
    margin-left: 12px;
    .label-extra-large-font();
  }

  .add-button {
    margin: 6px;
  }
}
</style>