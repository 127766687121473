<template>
  <div class="block-header">
    <div class="block-title" v-if="title">{{ title }}</div>
    <div class="spacer"></div>

    <!-- <GoogleSymbol
      symbol="expand_less"
      :hover="true"
      size="24px"
      padding="12px"
      class="theme"
    /> -->

    <GoogleSymbol
      v-if="designCenterIsVisible"
      symbol="more_vert"
      :hover="true"
      size="24px"
      padding="12px"
      @click="displayDialog = true"
      class="theme"
    />
  </div>

  <!-- DIALOG -->

  <Dialog :title="'Paramètre du bloc'" :show="displayDialog" class="dialog">
    <template v-slot:content>
      <Input
        :label="'Titre du bloc'"
        :id="'bloc-title'"
        class="dialog-input"
        v-model="blockTitleValue"
      />
    </template>

    <template v-slot:buttons>
      <div class="action-button" @click="displayDialog = false">Annuler</div>
      <div class="action-button" @click="updateBlockHeader">Valider</div>
    </template>
  </Dialog>
</template>

<script>
import GoogleSymbol from "@/components/GoogleSymbol";
import Dialog from "@/components/basics/Dialog";
import Input from "@/components/basics/Input";

export default {
  name: "block-header",
  components: { GoogleSymbol, Dialog, Input },
  props: {
    rowIndex: {
      type: Number,
      required: true,
    },
    blockIndex: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      displayDialog: false,
      blockTitleValue: this.title,
    };
  },
  computed: {
    designCenterIsVisible() {
      return this.$store.state.design.designCenterIsVisible;
    },
  },
  methods: {
    updateBlockHeader() {
      if (this.blockTitleValue.length > 0) {
        this.$store.dispatch("design/addBlockTitle", {
          rowIndex: this.rowIndex,
          blockIndex: this.blockIndex,
          title: this.blockTitleValue,
        });
      } else {
        this.$store.dispatch("design/removeBlockTitle", {
          rowIndex: this.rowIndex,
          blockIndex: this.blockIndex,
        });
      }
      this.displayDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.block-header {
  z-index: 1;
  width: 100%;
  background-color: @theme-background-secondary-color;
  color: @theme-font-color;
  border-radius: 12px 12px 0 0;
  padding: 12px 30px;
  min-height: 64px;

  .flex-row-v-center();
  .flex-row-h-right();

  .block-title {
    .label-extra-large-font();
  }
}

.overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.15);
}

// Dialog
.dialog {
  .dialog-input {
    margin-top: 24px;
  }

  .button-container {
    height: 88px;
    width: 100%;
    padding: 24px;
    .flex-row-h-right();

    .action-button {
      height: 40px;
      margin-left: 8px;
      padding: 10px 12px;
      cursor: pointer;
      border-radius: 6px;
      .label-large-font();

      &:hover {
        background-color: rgba(0, 0, 0, 0.07);
      }
    }
  }
}
</style>