<template>
  <Select
    :label="label"
    :id="id"
    v-model="model"
    @input="updateValue"
    :options="filteredOptions"
  />
</template>

<script>
import Select from "@/components/basics/Select";

export default {
  name: "custom-select",
  components: { Select },
  data() {
    return {
      model: this.modelValue,
    };
  },
  props: {
    // Actual field data

    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    apiIndex: {
      type: String,
      required: true,
    },
    apiUrl: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },

    // References data

    referenceKey: {
      type: String,
      required: true,
    },
    referenceIndex: {
      type: String,
      required: true,
    },
    referenceUrl: {
      type: String,
      required: true,
    },
    referenceDataIndex: {
      type: Array,
      required: true,
    },
  },
  watch: {
    model: function (newValue) {
      this.$emit("update:modelValue", newValue);
    },
    modelValue: function (newValue) {
      this.model = newValue;
    },
  },
  computed: {
    filteredOptions() {
      const apiData = this.getApiData(this.referenceIndex);
      const filteredData = [];
      let data, label;

      for (let i in apiData) {
        label = "";
        for (let j in this.referenceDataIndex) {
          if (!this.referenceDataIndex[j].hide) {
            label +=
              this.getDataByDataIndex(this.referenceDataIndex[j], apiData[i]) +
              " ";
          }
        }

        data = {
          label: label,
          value: apiData[i][this.referenceKey],
        };

        filteredData.push(data);
      }
      return filteredData;
    },
  },
  methods: {
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
    getApiData(apiIndex) {
      return this.$store.getters["api/getApiDataByApiIndex"](apiIndex);
    },
    getApiDataByTableNames(dataIndex) {
      return this.$store.getters["api/getApiDataByTableNames"](dataIndex);
    },
    getDataByDataIndex({ linkIndexes, fieldName }, data) {
      if (linkIndexes) {
        for (let i in linkIndexes) {
          data = data[linkIndexes[i]];
        }
      }

      return data[fieldName];
    },
  },
  async created() {
    await this.$store.dispatch("api/get", {
      url: this.referenceUrl,
      apiIndex: this.referenceIndex,
    });
  },
};
</script>